//

@media only screen and (min-width: 320px) and (max-width: 400px) {
	/*--- Mobile portrait ---*/
	.board {
		&--container {
			flex-direction: column;
		}
	}
}

@media only screen and (min-width: 400px) and (max-width: 480px) {
	/*--- Mobile portrait ---*/
	.board {
		&--container {
			flex-direction: column;
		}
	}
}

@media only screen and (min-width: 480px) and (max-width: 595px) {
	/*--- Mobile landscape ---*/
	.board {
		&--container {
			flex-direction: column;
		}
	}
}

@media only screen and (min-width: 595px) and (max-width: 690px) {
	/*--- Small tablet portrait ---*/
	.board {
		&--container {
			flex-direction: column;
		}
	}
}

@media only screen and (min-width: 690px) and (max-width: 800px) {
	/*--- Tablet portrait ---*/
	.board {
		&--container {
			flex-direction: column;
		}
	}
}

@media only screen and (min-width: 801px) and (max-width: 900px) {
	/*--- Small tablet landscape ---*/
}

@media only screen and (min-width: 900px) and (max-width: 1024px) {
	/*--- Small tablet landscape ---*/
}

@media only screen and (min-width: 1024px) and (max-width: 1100px) {
	/*--- Tablet landscape --- */
}

@media only screen and (min-width: 1100px) and (max-width: 1224px) {
	/*--- Tablet landscape --- */
}

@media only screen and (min-width: 1224px) {
	/*--- Laptop --- */
}

@media only screen and (min-width: 1824px) {
	/*--- Large Screen --- */
}

@media only screen and (max-width: 550px) {
	.board {
		&--header {
			justify-content: center;
			padding-left: 0;

			font-size: 1.5rem;
			font-weight: 500;
			letter-spacing: 0.0075em;
		}

		&--guesses {
			margin: 2px auto;
			padding: 12px;
			font-size: 1em;
		}

		&--restart-button {
			font-size: 1em;
			padding: 10px 20px;
			margin: 15px auto 20px auto;
		}

		&--result {
			font-size: 1.2em;
			margin: 5px auto;
			padding: 10px;
			line-height: 1.3em;
			max-width: 95%;
		}
	}
}

@media only screen and (min-width: 551px) {
	.board {
		&--header {
			font-size: 1.5rem;
			font-weight: 500;
			letter-spacing: 0.0075em;

			padding-left: 50px;
		}

		&--guesses {
			margin: 20px auto;
			padding: 20px;
			font-size: 1.2em;
		}

		&--restart-button {
			font-size: 22px;
			padding: 16px;
			margin: 15px auto 20px auto;
		}

		&--result {
			font-size: 1.4em;
			margin: 20px auto;
			padding: 20px;
			line-height: 1.5em;
			max-width: 90%;
		}
	}
}

@media only screen and (max-width: 481px) {
	.platform {
		margin: 10px auto;
		padding: 10px;
		img {
			width: 150px;
		}
	}
}
@media only screen and (min-width: 481px) and (max-width: 550px) {
	.platform {
		margin: 10px auto;
		padding: 10px;
		img {
			width: 150px;
		}
	}
}
@media only screen and (min-width: 550px) and (max-width: 750px) {
	.platform {
		margin: 10px auto;
		padding: 10px;
		img {
			width: 200px;
		}
	}
}
@media only screen and (min-width: 751px) {
	.platform {
		margin: 20px 0px 20px 20px;
		padding: 15px;
		img {
			width: 250px;
		}
	}
}

@mixin word-calculator($start, $incr) {
	@for $i from 0 through 23 {
		$spos: $start + ($i * $incr);
		// @debug "spos #{$spos}";
		$epos: $start + (($i + 1) * $incr);
		// @debug "epos #{$epos}";
		$mpos: $start + ($i * $incr) -10;
		// @debug "mpos #{$mpos}";
		@media only screen and (min-width: $spos) and (max-width: $epos) {
			.word {
				max-width: $mpos;
			}
		}
	}
}

@include word-calculator(320px, 20);

@media only screen and (max-width: 480px) {
	.keyboard {
		@include box-shadow(
			(0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12))
		);

		max-width: 95%;
		margin: 0 8px 0px 8px;
		padding: 5px;

		&--key {
			font-size: 1em;

			margin: 5px;
			width: 32px;
			height: 32px;
		}
	}

	.word {
		margin: 10px auto;
		padding: 10px;

		&--key {
			font-size: 1em;

			margin: 5px;
			width: 16px;
			height: 16px;
		}
	}
}

@media only screen and (min-width: 480px) and (max-width: 750px) {
	.keyboard {
		max-width: 95%;
		margin: 0 auto;
		padding: 5px;

		&--key {
			font-size: 1.3em;

			margin: 5px;
			width: 40px;
			height: 40px;
		}
	}

	.word {
		margin: 20px auto 20px auto;
		padding: 20px;

		&--key {
			font-size: 1.3em;

			margin: 3px;
			width: 12px;
			height: 20px;
		}
	}
}

@media only screen and (min-width: 801px) {
	.word {
		max-width: 90%;
	}
}

@media only screen and (min-width: 751px) {
	.keyboard {
		max-width: 95%;
		margin: 0 30px 30px 30px;
		padding: 12px;

		&--key {
			margin: 10px;
			width: 60px;
			height: 60px;
		}
	}

	.word {
		margin: 20px auto 40px auto;
		padding: 20px;

		&--key {
			margin: 10px;
			width: 35px;
			height: 35px;
		}
	}
}

@media only screen and (min-width: 1200px) {
	.keyboard {
		max-width: 800px;
	}
}

@media only screen and (max-width: 480px) {
	.clues {
		margin: 10px auto 0px auto;
		padding: 5px;

		max-width: 95%;

		&--category {
			font-size: 1em;
		}

		li {
			margin: 5px 20px;
			font-size: 0.9em;
		}
	}
}

@media only screen and (min-width: 480px) and (max-width: 800px) {
	.clues {
		margin: 10px auto 0px auto;
		padding: 5px;

		max-width: 95%;

		&--category {
			font-size: 1.2em;
		}

		li {
			margin: 5px 20px;
			font-size: 1em;
		}
	}
}

@media only screen and (min-width: 800px) {
	.clues {
		margin: 30px auto 0px auto;
		padding: 20px;

		max-width: 90%;

		&--category {
			font-size: 1.4em;
			padding: 8px 0;
		}

		li {
			margin: 5px 20px;
			font-size: 1.1em;
		}
	}
}
