// 

.keyboard {
	@extend %boxshadow2;
	@extend %borderRadius;

	background-color: $secondary-color;

	&--key {
		@extend %letters;

		border: 1px solid $dark-primary-color;
		border-radius: 10px;
		outline: none;
	}

	&--key.used {
		background-color: $dark-primary-color;
		color: $dark-primary-color;
		@include opacity(0.5);
	}

	&--key.unused {
		background-color: $secondary-color;
		color: $dark-primary-color;
		@include opacity(1);

		&:hover {
			background-color: $dark-primary-color;
			color: white;
      @include transition(all 0.3s);
      cursor: pointer;
    }
	}
}
