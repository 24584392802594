//

$primary-color: #428bca;
$dark-primary-color: #3071a9;
$darker-primary-color: #093e6c;

$secondary-color: #f0f0f0;
$dark-secondary-color: #afafaf;

$victory-color: green;
$defeat-color: red;

$header-height: 64px;

@import 'setup';
@import 'mixins';

%boxshadow {
	@include box-shadow((0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)));
}

%boxshadow2 {
	@include box-shadow(0px 6px 20px 2px rgba(0,0,0,0.53));
}

%boxshadow3 {
	@include box-shadow(1px 1px 6px 1px rgba(0,0,0,0.53));
}

%borderRadius {
	@include rounded(5px);
}

%letters {
	font-size: 1.6em;
	font-weight: 500;
}

@import 'board';
@import 'platform';
@import 'keyboard';
@import 'word';
@import 'clues';

@import 'responsive';
