// 

.word {
	@extend %boxshadow;
	@extend %borderRadius;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	background-color: $secondary-color;

	&--key {
		@extend %letters;

		display: flex;
		justify-content: center;
		align-items: center;

		border-bottom: 1px solid $dark-primary-color;

		color: $dark-primary-color;
		background-color: $secondary-color;
		outline: none;
	}
}
