//

@mixin center() {
	display: flex;
	justify-content: center;
	align-items: center
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin rounded($radius: 4px) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin button-to-div {
	border: none transparent;
	outline: none;
	text-decoration: none;
	border-radius: 2px;
	line-height: normal;
	white-space: nowrap;
	text-align: center;
	cursor: pointer;
	text-rendering: auto;
}

@mixin linearGradient($top, $bottom){
	background: $top; /* Old browsers */
	background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
	background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}
