//

.board {
	&--header {
		@extend %boxshadow;

		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 10;
		background-color: $secondary-color;
		color: $dark-primary-color;

		display: flex;
		justify-content: left;
		align-items: center;

		height: $header-height;
		width: 100%;
	}

	&--container {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;

		margin-top: $header-height;
	}

	&--controls {
		display: flex;
		flex-direction: column;
	}

	&--guesses {
		@extend %boxshadow;
		@extend %borderRadius;

		color: $darker-primary-color;
		background-color: $secondary-color;
	}

	&--result {
		@extend %boxshadow;
		@extend %borderRadius;
		background-color: $secondary-color;
		color: $darker-primary-color;

		&.victory {
			color: $victory-color;
		}
		&.defeat {
			color: $defeat-color;
		}
	}

	&--restart {
		&-button {
			@extend %boxshadow3;
			@extend %borderRadius;
			border: none transparent;

			background-color: $darker-primary-color;
			color: white;
			text-decoration: none;
			outline: none;
			&:hover {
				background-color: $dark-primary-color;
				@include transition(all 0.3s);
				cursor: pointer;
				@include opacity(1);
			}
		}
	}
}
