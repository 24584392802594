// 

.platform {
	@extend %boxshadow;
	@extend %borderRadius;

	background-color: $secondary-color;

	img {
		@extend %boxshadow;
		@extend %borderRadius;

		height: auto;
	}
}
