// 

.clues {

	@extend %boxshadow;
	@extend %borderRadius;

	text-align: left;

	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	color: $darker-primary-color;
	background-color: $secondary-color;

	&--category {
		text-align: center;
	}
}