//

$font-family: 'Roboto', sans-serif;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
	min-height: 100%;
}

body {
  font-family: $font-family;
  font-size: 16px;
	color: #444;
  text-align: center;
	line-height: 1.2;
	background: linear-gradient(to right bottom, $primary-color, $secondary-color) !important;
}

figure,
figcaption,
h1,
h2,
h3 {
  padding: 0;
  margin: 0;
}

input,
textarea,
select,
button {
  font-family: $font-family;
}
